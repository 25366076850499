/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import { Div, ButtonLink } from '@schibsted-smb/fireball';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { StaticImageData } from 'next/image';

import { Link } from '~components/base/Link';
import { Paragraph } from '~components/base/Paragraph';
import { Span } from '~components/base/Span';
import { Title } from '~components/base/Title';
import { HeroImage } from '~components/elements/Hero/HeroImage';
import { LINKS } from '~utils/links';

import { SectionWrapper } from '../SectionWapper';

interface HeroProps {
  title?: string;
  titleWidth?: string[];
  category?: string;
  subtitle?: string;
  subtitleWidth?: string[];
  image: StaticImageData;
  imageAltText: string;
  height?: string[];
  titleWithSpan?: boolean;
  pt?: string[];
  titleMb?: string[];
  noMobileImage?: boolean;
  hasRegButton?: boolean;
}

export const Hero: React.FC<React.PropsWithChildren<HeroProps>> = ({
  title,
  titleWidth,
  category,
  subtitle,
  subtitleWidth,
  image,
  imageAltText,
  height,
  pt,
  titleWithSpan,
  children,
  titleMb,
  noMobileImage,
  hasRegButton,
}) => {
  const { t } = useTranslation();
  return (
    <Div
      position="relative"
      width="100%"
      height={height || '100%'}
      minHeight={['169px', '209px', null, '254px', '454px', '823px', '823px', '841px', null, '800px']}
    >
      <SectionWrapper
        hasContainer
        divPaddingTop="0px"
        sectionHeight="100%"
        containerHeight="100%"
        containerPaddingX="10px"
      >
        <Div
          position="relative"
          display="flex"
          flexDirection="column"
          zIndex="1"
          height="100%"
          pt={pt || ['48px', null, null, null, null, null, '72px', null, null, '88px', null]}
        >
          {category && (
            <Paragraph
              size="xl"
              fontWeight={600}
              width={['100%', null, '300px', null, '350px', null, null, null, null, '100%']}
              mb="8px"
            >
              {category}
            </Paragraph>
          )}

          {title && (
            <Title.H1
              width={titleWidth || null}
              fontWeight={titleWithSpan ? 'medium' : 'bold'}
              mb={titleMb || ['24px', null, null, '48px', null, '24px', '32px', null, '48px']}
            >
              {
                // eslint-disable-next-line react/jsx-no-useless-fragment
                titleWithSpan ? (
                  // eslint-disable-next-line react/jsx-key
                  <Trans i18nKey={title} components={[<Span fontWeight="bold" display="block" />]} />
                ) : (
                  <>{title}</>
                )
              }
            </Title.H1>
          )}

          {subtitle && (
            <Paragraph
              size="l"
              fontWeight={600}
              width={subtitleWidth || null}
              mb={['32px', null, null, '48px', null, null, null, null, '0px', '48px']}
            >
              {subtitle}
            </Paragraph>
          )}
        </Div>
        <Div display={noMobileImage ? ['none', null, null, null, null, 'block'] : 'block'}>
          <HeroImage src={image} alt={imageAltText} />
        </Div>
        <Div display={hasRegButton ? ['block', null, null, null, 'none'] : 'none'}>
          <Link href={`${LINKS.REGISTER.PROJECT.START}?location=header`} hasAnchor>
            <ButtonLink size="small" variant="primary" fontWeight="bold" px="24px" display="flex">
              {t('common:header.desktop.registerProject')}
            </ButtonLink>
          </Link>
        </Div>
      </SectionWrapper>

      <Div position="relative" zIndex="1" width="100%">
        {children}
      </Div>
    </Div>
  );
};
