import React, { useState } from 'react';

import { Div } from '@schibsted-smb/fireball';
import { ArrowRightFlat } from '@schibsted-smb/fireball-icons-prosjektpluss';

import { Link } from '~components/base/Link';
import { Paragraph, lFontSize } from '~components/base/Paragraph';
import { StyledIconWrapper } from '~components/elements/TextLink/style';

interface TextLinkProps {
  href: string;
  width?: number[] | number | string | string[];
  icon?: boolean;
  mt?: number[] | number | string | string[];
}

const variants = {
  enter: {
    x: -12,
    transition: {
      repeat: Infinity,
      repeatDelay: 1.8,
      type: 'spring',
      damping: 25,
      mass: 1,
      stiffness: 500,
    },
  },
  exit: { x: 0 },
};

export const TextLink: React.FC<React.PropsWithChildren<TextLinkProps>> = ({ children, icon, width, mt, href }) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <Div
      display="flex"
      mt={mt || null}
      width={width || null}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Link href={href} fontWeight={600} fontSize={lFontSize} borderBottom noUnderline>
        <Div display="flex" alignItems="center" pb="3px">
          <Paragraph size="l" fontWeight={600} lineHeight="130%">
            {children}
          </Paragraph>

          {icon && (
            <StyledIconWrapper initial="exit" animate={isHovered ? 'enter' : 'exit'} variants={variants}>
              <ArrowRightFlat width="100%" height="100%" fill="black.black9" />
            </StyledIconWrapper>
          )}
        </Div>
      </Link>
    </Div>
  );
};
