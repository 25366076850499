import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const CarouselWrapper = styled(Div)`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const CarouselItemWrapper = styled(Div)`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 32px 40px 32px;
  box-shadow: 0 18px 20px -20px rgba(0, 0, 0, 0.15), 0px 30px 60px -20px rgba(50, 50, 93, 0.1);
  border-radius: 5px;
`;
