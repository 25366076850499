import React from 'react';

import { Div } from '@schibsted-smb/fireball';
import { CheckmarkColor } from '@schibsted-smb/fireball-icons-prosjektpluss';
import useTranslation from 'next-translate/useTranslation';

import { SectionWrapper } from '~components/elements/SectionWapper';
import { TextWithImage } from '~components/elements/TextWithImage';
import { List } from '~components/pages/common/ListBanner/List';
import { TextSection } from '~components/pages/common/TextSection';
import image from '~static/images/pages/frontPage/frontpage-quality.jpg';
import { LINKS } from '~utils/links';

const textSection = [
  {
    text: 'frontpage:qualitysection.1.text',
  },
  {
    text: 'frontpage:qualitysection.2.text',
  },
  {
    text: 'frontpage:qualitysection.3.text',
  },
];

const listData = [
  'frontpage:qualitySection.points.1',
  'frontpage:qualitySection.points.2',
  'frontpage:qualitySection.points.3',
];

interface IQualityProps {
  hasList?: boolean;
}

export const QualitySection: React.FC<IQualityProps> = ({ hasList }) => {
  const { t } = useTranslation();

  return (
    <SectionWrapper hasContainer>
      <TextWithImage
        title={t('frontpage:qualitysection.title')}
        subtitle={t('frontpage:qualitysection.subtitle')}
        buttonText={t('frontpage:qualitysection.buttonText')}
        image={image}
        imageHeight={['240px', null, null, '300px', null, '360px', '800px']}
        altImageText={t('frontpage:qualitysection.imageAltText')}
        href={LINKS.QUALITY_ASSURANCE}
      >
        <Div
          display="flex"
          flexWrap="wrap"
          flexDirection={['column', null, null, null, 'row', null, 'column']}
          justifyContent={['flex-start', null, null, null, 'space-between', null, 'flex-start']}
        >
          {textSection.map((section) => (
            <TextSection
              key={section.text}
              width={[1, null, null, null, 0.48, null, 1]}
              text={section.text}
              textWithSpanWeight="bold"
            />
          ))}
          {hasList && listData && (
            <Div mb="8px" width={[1, null, null, null, 0.48, null, 1]}>
              <List data={listData} fontWeight={600} displayBullets icon={CheckmarkColor} />
            </Div>
          )}
        </Div>
      </TextWithImage>
    </SectionWrapper>
  );
};
