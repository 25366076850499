import React from 'react';

import { LatterFlat } from '@schibsted-smb/fireball-icons-prosjektpluss';

import { SectionContainer } from '~components/pages/common/SectionContainer';
import { WorkTypes } from '~components/pages/common/WorkTypes';
import { consumerWorktypes } from '~components/pages/common/WorkTypes/list';

export const WorkTypesBanner: React.FC = () => (
  <SectionContainer title="Hva er ditt neste prosjekt?" icon={LatterFlat} center>
    <WorkTypes worktypes={consumerWorktypes} />
  </SectionContainer>
);
