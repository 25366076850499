import { LINKS } from '~utils/links';

export const partnerData = [
  {
    name: 'Svenn',
    description: 'partners:companies.svenn.description',
    image: '/static/images/partners/svenn.jpg',
    imageGray: '/static/images/partners/svenn_gray.jpg',
    url: `${LINKS.PARTNERS}/svenn`,
    urlIsInternal: true,
  },
  {
    name: 'Norges hyggeligste',
    description: 'partners:companies.nhh.description',
    image: '/static/images/partners/norgeshyggeligste.jpg',
    imageGray: '/static/images/partners/norgeshyggeligste_gray.jpg',
    url: 'https://hyggeligste.no/',
    urlIsInternal: false,
  },
  {
    name: 'Sirken',
    description: 'partners:companies.sirken.description',
    image: '/static/images/partners/sirken.jpg',
    imageGray: '/static/images/partners/sirken_gray.jpg',
    url: 'https://sirken.no/',
    urlIsInternal: false,
  },
];
