import React from 'react';

import { Div } from '@schibsted-smb/fireball';
import useTranslation from 'next-translate/useTranslation';

import { SectionWrapper } from '~components/elements/SectionWapper';
import { TextWithImage } from '~components/elements/TextWithImage';
import { TextSection } from '~components/pages/common/TextSection';
import image from '~static/images/pages/frontPage/frontpage-explanation.jpg';
import { LINKS } from '~utils/links';

const textSection = [
  {
    title: 'frontpage:faqsection.1.title',
    text: 'frontpage:faqsection.1.text',
  },
  {
    title: 'frontpage:faqsection.3.title',
    text: 'frontpage:faqsection.3.text',
  },
  {
    title: 'frontpage:faqsection.2.title',
    text: 'frontpage:faqsection.2.text',
  },
];

export const FaqSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SectionWrapper hasContainer>
      <TextWithImage
        title={t('frontpage:faqsection.title')}
        subtitle={t('frontpage:faqsection.subtitle')}
        image={image}
        imageHeight={['240px', null, null, '300px', null, '360px', '996px', '896px']}
        altImageText={t('frontpage:faqsection.imageAltText')}
        buttonText={t('common:registerProject')}
        href={LINKS.REGISTER.PROJECT.START}
      >
        <Div
          display="flex"
          flexWrap="wrap"
          flexDirection={['column', null, null, null, 'row', null, 'column']}
          justifyContent={['flex-start', null, null, null, 'space-between', null, 'flex-start']}
        >
          {textSection.map((section) => (
            <TextSection
              key={section.text}
              width={[1, null, null, null, 0.48, null, 1]}
              title={section.title}
              text={section.text}
              textWithSpanWeight="bold"
            />
          ))}
        </Div>
      </TextWithImage>
    </SectionWrapper>
  );
};
