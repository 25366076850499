import React from 'react';

import { ListItem } from '~components/pages/common/ListBanner/List/ListItem';

import { ItemList } from './style';

interface ListProps {
  data: string[];
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  displayBullets?: boolean;
  fontWeight?: number;
}

export const List: React.FC<ListProps> = ({ data, fontWeight, icon, displayBullets }) => (
  <ItemList>
    {data.map((item) => (
      <ListItem key={item} title={item} fontWeight={fontWeight || 400} icon={icon} displayBullets={displayBullets} />
    ))}
  </ItemList>
);
