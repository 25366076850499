import React from 'react';

import { Container } from '@schibsted-smb/fireball';
import useTranslation from 'next-translate/useTranslation';

import { Paragraph } from '~components/base/Paragraph';
import { Title } from '~components/base/Title';
import { SectionWrapper } from '~components/elements/SectionWapper';
import { Testimonial } from '~components/pages/frontPage/Testimonials/data';

import { Carousel } from './Carousel';

interface TestimonialsProps {
  data: Testimonial[];
  title: string;
  noRating?: boolean;
}

export const Testimonials: React.FC<TestimonialsProps> = ({ data, title, noRating }) => {
  const { t } = useTranslation();

  return (
    <SectionWrapper sectionHeight="100%" divPaddingX="10px">
      <Container maxWidth={[311, null, 350, 444, 585, 876, 1072, 1198, 1374, 1520]}>
        <Paragraph fontWeight="bold" mb="16px" color="#38999F" fontSize="20px">
          Tilbakemeldinger
        </Paragraph>
        <Title.H2 mb="48px" width={[1, null, null, null, null, null, 0.5]}>
          {t(title)}
        </Title.H2>
      </Container>
      <Carousel data={data} delay={3500} noRating={noRating} />
    </SectionWrapper>
  );
};
