import React from 'react';

import { DivProps } from '@schibsted-smb/fireball';

import { YoutubeEmbed } from '~components/elements/YoutubeEmbed';
import { SectionContainer } from '~components/pages/common/SectionContainer';

interface VideoSectionProps {
  embedId: string;
  title: string;
  subtitle?: string;
  subtitleColor?: string;
  divPaddingTop?: DivProps['pt'];
}

export const VideoSection: React.FC<VideoSectionProps> = ({
  embedId,
  title,
  subtitle,
  subtitleColor,
  divPaddingTop,
}) => (
  <>
    <div id="tips-section" />
    <SectionContainer title={title} subtitle={subtitle} subtitleColor={subtitleColor} divPaddingTop={divPaddingTop}>
      <YoutubeEmbed embedId={embedId} title={title} />
    </SectionContainer>
  </>
);
