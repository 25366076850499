import styled from 'styled-components';

interface CommonProps {
  contentCentered?: boolean;
  sticky?: boolean;
  overlap?: boolean;
  isOpen?: boolean;
}

export const WorkTypesGrid = styled.ul<CommonProps>`
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  @media (min-width: 320px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 414px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 540px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }
`;

export const WorkTypesItem = styled.li<CommonProps>``;
