import React from 'react';

import { Div, DivProps } from '@schibsted-smb/fireball';

import { Paragraph } from '~components/base/Paragraph';
import { Title } from '~components/base/Title';
import { SectionWrapper } from '~components/elements/SectionWapper';

interface SectionContainerProps {
  title: string;
  subtitle?: string;
  description?: string;
  subtitleColor?: string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  center?: boolean;
  divPaddingTop?: DivProps['pt'];
}

export const SectionContainer: React.FC<React.PropsWithChildren<SectionContainerProps>> = ({
  children,
  title,
  subtitle,
  icon: Icon,
  center,
  subtitleColor,
  description,
  divPaddingTop,
}) => (
  <SectionWrapper hasContainer containerPaddingX="10px" divPaddingTop={divPaddingTop}>
    <Div display="flex" flexDirection="column" alignItems={center ? 'center' : 'flex-start'}>
      <Div
        display="flex"
        flexDirection="column"
        alignItems={center ? 'center' : 'flex-start'}
        width={[1, null, null, null, null, 0.5]}
        pr={center ? null : ['0px', null, null, null, null, null, '56px']}
        mr={center ? null : ['0px', null, null, null, null, null, '8px']}
      >
        {Icon && (
          <Div width="48px" height="48px" mb="24px">
            <Icon width="100%" height="100%" fill="black.black10" />
          </Div>
        )}

        {subtitle && (
          <Paragraph fontSize="20px" fontWeight="bold" mb="16px" color={subtitleColor || '#38999F'}>
            {subtitle}
          </Paragraph>
        )}

        <Title.H2 mb={description ? '24px' : '48px'}>{title}</Title.H2>
      </Div>
      {description && <Paragraph mb="48px">{description}</Paragraph>}
      {children}
    </Div>
  </SectionWrapper>
);
