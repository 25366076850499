import React from 'react';

import { breakpoints, Div, DivProps } from '@schibsted-smb/fireball';
import NextImage, { StaticImageData } from 'next/image';
import styled from 'styled-components';

interface HeroImageProps {
  src: StaticImageData;
  alt: string;
  position?: DivProps['position'];
}

const Image = styled(NextImage)`
  object-fit: cover;
  object-position: left;
`;

export const HeroImage: React.FC<HeroImageProps> = ({ src, alt, position }) => (
  <Div
    position={position || ['relative', null, null, null, 'absolute']}
    top="0px"
    right="0px"
    zIndex="0"
    height={['169px', '209px', null, '254px', '454px', '823px', '823px', '841px', null, '800px']}
    width={['100%', null, null, null, '508px', '615px', '815px', null, '950px', '1084px', '1184px']}
    borderBottomLeftRadius="3px"
    overflow="hidden"
  >
    <Image
      src={src}
      alt={alt}
      sizes={`(max-width: ${breakpoints.tabletSm}) 100vw, (max-width: ${breakpoints.desktop}) 85vw, 50vw`}
      fill
      priority
    />
  </Div>
);
