/* eslint-disable consistent-return */
import React from 'react';

import { WorkTypesGrid, WorkTypesItem } from '~components/pages/common/WorkTypes/style';
import { WorkTypesLink } from '~components/pages/common/WorkTypes/WorkTypesLink';
import { LINKS } from '~utils/links';

import { HouseType } from '../../register/Project/utilities';
import { WorkTypes as WorkTypeProps } from './list';

interface WorkTypesProps {
  worktypes: WorkTypeProps[];
  isAssociation?: boolean;
  prefetch?: boolean;
}

export const WorkTypes: React.FC<WorkTypesProps> = ({ worktypes, isAssociation = false, prefetch }) => {
  const baseUrl = isAssociation
    ? `${LINKS.REGISTER.PROJECT.START}?houseType=${HouseType.HOUSING_ASSOCIATION}`
    : LINKS.REGISTER.PROJECT.START;

  return (
    <WorkTypesGrid>
      {worktypes?.map(({ name, icon, types, promote }) => {
        if (!promote) return;
        const stringTypes = types?.join('&type=') || '';
        const url = isAssociation
          ? `${baseUrl}${stringTypes ? `&type=${stringTypes}` : ''}`
          : `${baseUrl}?type=${stringTypes}`;

        return (
          <WorkTypesItem key={name}>
            <WorkTypesLink name={name} icon={icon} href={types?.length > 0 ? url : baseUrl} prefetch={prefetch} />
          </WorkTypesItem>
        );
      })}
    </WorkTypesGrid>
  );
};
