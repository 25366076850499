import React from 'react';

import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CarouselItem } from '~components/pages/frontPage/Testimonials/Carousel/CarouselItem';
import { useWindowSize } from '~hooks/useWindowSize';

import { Testimonial } from '../data';
import { CarouselWrapper } from './style';

import 'swiper/css';
import 'swiper/css/pagination';

interface CarouselProps {
  data: Testimonial[];
  delay?: number;
  noRating?: boolean;
}

export const Carousel: React.FC<CarouselProps> = ({ data, delay, noRating }) => {
  const viewPortIsSmall = useWindowSize().width < 950;
  const viewPortIsMedium = useWindowSize().width < 1320;

  return (
    <CarouselWrapper>
      <Swiper
        // eslint-disable-next-line no-nested-ternary
        slidesPerView={viewPortIsSmall ? 1 : viewPortIsMedium ? 2 : 3}
        spaceBetween={32}
        loop
        loopFillGroupWithBlank
        autoplay={{
          delay: delay || 15000,
          disableOnInteraction: false,
        }}
        scrollbar={{
          draggable: true,
        }}
        navigation
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Navigation]}
        style={
          viewPortIsSmall
            ? { paddingRight: '0px', paddingLeft: '0px' }
            : { paddingRight: '128px', paddingLeft: '128px' }
        }
      >
        {data.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <SwiperSlide key={`${item.name}-${index}`}>
            <CarouselItem noRating={noRating} data={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </CarouselWrapper>
  );
};
