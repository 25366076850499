import React from 'react';

import { breakpoints, ButtonLink, Div } from '@schibsted-smb/fireball';
import NextImage, { StaticImageData } from 'next/image';
import styled from 'styled-components';

import { Link } from '~components/base/Link';
import { Title } from '~components/base/Title';

import { Paragraph } from '../../base/Paragraph';

interface TextWithImageProps {
  title: string;
  subtitle: string;
  subtitleColor?: string;
  image: StaticImageData;
  imageHeight?: string[];
  altImageText: string;
  buttonText?: string;
  href?: string;
}

const Image = styled(NextImage)`
  object-fit: cover;
  object-position: center;
`;

export const TextWithImage: React.FC<React.PropsWithChildren<TextWithImageProps>> = ({
  children,
  title,
  subtitle,
  subtitleColor,
  image,
  imageHeight,
  altImageText,
  buttonText,
  href,
}) => (
  <Div
    position="relative"
    width={1}
    flexDirection={['column', null, null, null, null, null, 'row']}
    display="flex"
    px="10px"
    height="100%"
  >
    <Div
      width={['100%', null, null, null, null, null, '50%']}
      height="100%"
      mr={['0px', null, null, null, null, null, '8px']}
      pr={['0px', null, null, null, null, null, '56px']}
    >
      <Paragraph fontSize="20px" fontWeight="bold" mb="16px" color={subtitleColor || '#38999F'}>
        {subtitle}
      </Paragraph>
      <Title.H2 fontWeight="bold" mb={['32px', '48px']}>
        {title}
      </Title.H2>
      <Div>{children}</Div>
      {buttonText && href && (
        <Div mb={['48px', null, null, null, null, null, 0]}>
          <Link href={href} hasAnchor>
            <ButtonLink>{buttonText}</ButtonLink>
          </Link>
        </Div>
      )}
    </Div>

    <Div
      position="relative"
      width={['100%', null, null, null, null, null, '50%']}
      height="100%"
      minHeight={imageHeight || 1}
      ml={['0px', null, null, null, null, null, '8px']}
      borderRadius="3px"
      overflow="hidden"
    >
      <Image src={image} alt={altImageText} sizes={`(max-width: ${breakpoints.desktopSm}) 100vw, 50vw`} fill />
    </Div>
  </Div>
);
