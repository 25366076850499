import React from 'react';

import { Div } from '@schibsted-smb/fireball';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

import { Paragraph } from '~components/base/Paragraph';
import { Span } from '~components/base/Span';

interface TextSectionProps {
  title?: string;
  text?: string;
  textWithSpanWeight?: string;
  mb?: string[];
  ml?: string;
  width?: string | string[] | number | number[];
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const TextSection: React.FC<TextSectionProps> = ({
  title,
  text,
  width,
  textWithSpanWeight,
  icon: Icon,
  mb,
  ml,
}) => {
  const { t } = useTranslation();

  return (
    <Div display="flex" flexDirection="column" mb={mb || '32px'} width={width || 1}>
      {title && (
        <Paragraph fontWeight={600} mb="16px">
          {t(title)}
        </Paragraph>
      )}
      {text && (
        <Paragraph ml={ml || null} display={Icon ? 'flex' : 'block'}>
          {Icon && (
            <Div as="span" display="flex" alignContent="center" alignItems="center" mr="24px">
              <Icon width="32px" height="32px" fill="black.black10" />
            </Div>
          )}
          {textWithSpanWeight ? (
            // eslint-disable-next-line react/jsx-key
            <Trans i18nKey={text} components={[<Span fontWeight={textWithSpanWeight} />]} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>{t(text)}</>
          )}
        </Paragraph>
      )}
    </Div>
  );
};
