import { useColor, DefaultComposeProps, FlexComposeProps, defaultCompose, flexCompose } from '@schibsted-smb/fireball';
import styled from 'styled-components';

type StyledWorkTypesLinkProps = DefaultComposeProps & FlexComposeProps;

export const StyledWorkTypesLink = styled.a<StyledWorkTypesLinkProps>`
  background-color: ${() => useColor('black.black0')};
  color: ${() => useColor('black.black8')};
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  margin: 0;
  position: unset;
  transition: all ${({ theme }) => theme.animationSpeed.fast} ease-in-out;
  box-shadow: 0 18px 20px -20px rgba(0, 0, 0, 0.15), 0px 30px 60px -20px rgba(50, 50, 93, 0.1);
  height: 100%;

  &:hover {
    text-decoration: none;
    background-color: ${() => useColor('black.black0')};

    transform: translate(0, 5px);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }

  ${defaultCompose}
  ${flexCompose}
`;
