import React from 'react';

import { Div } from '@schibsted-smb/fireball';
import useTranslation from 'next-translate/useTranslation';

import { Paragraph } from '~components/base/Paragraph';
import { Hero } from '~components/elements/Hero';
import { HeroContent } from '~components/elements/Hero/HeroContent';
import { WorkTypes } from '~components/pages/common/WorkTypes';
import { consumerWorktypes } from '~components/pages/common/WorkTypes/list';
import image from '~static/images/pages/frontPage/frontpage-hero.jpg';

import { CheckMarkItem } from './CheckMarkItem';

export const HeroSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Hero
      title={t('frontpage:header.title')}
      titleWidth={['100%', null, '280px', '400px', null, '480px', '520px', '600px', null, '700px']}
      image={image}
      imageAltText="Et opppusset bad"
      hasRegButton
      noMobileImage
    >
      <HeroContent pt="48px">
        <Div as="ul" p={0} m={0} pb={['24px', null, null, '48px', null, null, '64px', '84px']}>
          <CheckMarkItem>{t('frontpage:header.checkmarks.1')}</CheckMarkItem>
          <CheckMarkItem>{t('frontpage:header.checkmarks.2')}</CheckMarkItem>
          <CheckMarkItem>{t('frontpage:header.checkmarks.3')}</CheckMarkItem>
        </Div>
        <Paragraph
          fontSize={['18px', null, null, '20px', '24px']}
          fontWeight="bold"
          width={['100%', null, '250px', null, '290px', null, null, '700px']}
          mb="32px"
        >
          Registrer prosjektet ditt - velg en kategori
        </Paragraph>
        <WorkTypes worktypes={consumerWorktypes} prefetch={false} />
      </HeroContent>
    </Hero>
  );
};
