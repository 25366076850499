import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledIconWrapper = styled(motion.div)`
  display: flex;
  align-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-left: 32px;
`;
