import React from 'react';

import { Div } from '@schibsted-smb/fireball';
import { ThickChecmarkColor } from '@schibsted-smb/fireball-icons-prosjektpluss';
import styled from 'styled-components';

import { Paragraph } from '~components/base/Paragraph';

const CheckMarkItemWrapper = styled(Div).attrs(() => ({ as: 'li', display: 'flex', gridGap: 4, mb: 6 }))`
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const CheckMarkItem: React.FC<React.PropsWithChildren> = ({ children }) => (
  <CheckMarkItemWrapper>
    <ThickChecmarkColor width={16} />
    <Paragraph size="m">{children}</Paragraph>
  </CheckMarkItemWrapper>
);
