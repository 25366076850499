import React from 'react';

import { breakpoints, Div } from '@schibsted-smb/fireball';
import NextImage from 'next/image';
import NextLink from 'next/link';
import styled from 'styled-components';

import { Link } from '~components/base/Link';
import { Title } from '~components/base/Title';
import { SectionWrapper } from '~components/elements/SectionWapper';
import { TextLink } from '~components/elements/TextLink';
import { partnerData } from '~components/pages/partnere/partnerData';
import { LINKS } from '~utils/links';

const imageSize = ['72px', null, null, '96px', null, '128px', '168px'];

const Image = styled(NextImage)`
  object-fit: contain;
`;

interface IPartnersSection {
  hasHeader?: boolean;
  title?: string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const PartnersSection: React.FC<IPartnersSection> = ({ title, icon: Icon, hasHeader }) => (
  <SectionWrapper
    hasContainer
    divPaddingTop={['48px', null, null, null, null, null, '52px', '68px', '76px', '100px']}
    containerPaddingX="10px"
  >
    {hasHeader && (
      <Div px="10px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        {Icon && (
          <Div width="60px" height="60px" mb="24px">
            <Icon width="100%" height="100%" fill="black.black10" />
          </Div>
        )}
        <Title.H2 px="10px" fontWeight="bold" mb={['32px', '64px']}>
          {title}
        </Title.H2>
      </Div>
    )}

    <Div
      display="flex"
      flexWrap={['wrap', null, null, null, null, 'nowrap']}
      alignItems="center"
      justifyContent="space-between"
    >
      {partnerData.map((item) => (
        <Div key={item.name} width={imageSize} height={imageSize} position="relative">
          {item.urlIsInternal ? (
            <NextLink href={item.url}>
              <Div position="relative" height="100%" width="100%">
                <Image
                  fill
                  src={item.imageGray}
                  alt={`Logo for ${item.name}`}
                  sizes={`(max-width: ${breakpoints.tabletSm}) 33vw, 25vw`}
                />
              </Div>
            </NextLink>
          ) : (
            <Link href={item.url}>
              <Div position="relative" height="100%" width="100%">
                <Image
                  fill
                  src={item.imageGray}
                  alt={`Logo for ${item.name}`}
                  sizes={`(max-width: ${breakpoints.tabletSm}) 33vw, 25vw`}
                />
              </Div>
            </Link>
          )}
        </Div>
      ))}
      <TextLink
        href={LINKS.PARTNERS}
        icon
        width={['100%', null, null, '272px', '311px']}
        mt={['32px', null, null, null, null, '0px']}
      >
        Les mer om våre samarbeidspartnere
      </TextLink>
    </Div>
  </SectionWrapper>
);
