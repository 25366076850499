import React from 'react';

import { Div, Rating } from '@schibsted-smb/fireball';
import { QuoteFlat } from '@schibsted-smb/fireball-icons-common';

import { Paragraph } from '~components/base/Paragraph';
import { CarouselItemWrapper } from '~components/pages/frontPage/Testimonials/Carousel/style';

import { Testimonial } from '../../data';

interface CarouselItemProps {
  data: Testimonial;
  noRating?: boolean;
}

export const CarouselItem: React.FC<CarouselItemProps> = ({ data, noRating }) => (
  <Div height="100%" pb="88px">
    <CarouselItemWrapper>
      <QuoteFlat width="48px" height="48px" fill="black.black10" />

      <Paragraph fontWeight="bold" color="black.black9" mb="16px" textAlign="center">
        {data.name}
      </Paragraph>

      <Paragraph size="xs" mb="32px" textAlign="center">
        {data.text}
      </Paragraph>
      {!noRating && <Rating score={data.rating} size={20} color="black.black9" />}
    </CarouselItemWrapper>
  </Div>
);
