import React from 'react';

import { Div } from '@schibsted-smb/fireball';
import { ArrowRightFlat } from '@schibsted-smb/fireball-icons-prosjektpluss';

import { Link } from '~components/base/Link';
import { Paragraph } from '~components/base/Paragraph';

import { StyledWorkTypesLink } from './style';

interface WorkTypesLinkProps {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  name: string;
  href: string;
  prefetch?: boolean;
}

const iconBreakpoints = ['34px', null, '40px', '28px', null, '32px', null, null, '44px'];

export const WorkTypesLink: React.FC<WorkTypesLinkProps> = ({ href, icon: Icon, name, prefetch, ...props }) => (
  <Link href={href} prefetch={prefetch} hasAnchor>
    <StyledWorkTypesLink
      mx={0}
      display="flex"
      alignItems="center"
      alignContent="center"
      px={[0, null, null, '18px', '24px']}
      py={['24px', null, null, null, null, null, null, '32px', null, '42px']}
      width="100%"
      justifyContent="space-between"
      {...props}
    >
      <Div
        display="flex"
        alignItems="center"
        alignContent="center"
        justifyContent={['center', null, null, 'flex-start']}
        width={1}
        flexDirection={['column', null, null, 'row']}
      >
        <Div
          width={iconBreakpoints}
          height={iconBreakpoints}
          minWidth={iconBreakpoints}
          minHeight={iconBreakpoints}
          mr={[0, null, null, '18px', null, '32px']}
        >
          <Icon width="100%" height="100%" fill="black.black10" />
        </Div>
        <Paragraph size="xs">{name}</Paragraph>
      </Div>
      <Div
        width={['10px', null, null, '24px']}
        height={['10px', null, null, '24px']}
        display={['none', null, null, null, 'block']}
      >
        <ArrowRightFlat width="100%" height="100%" fill="black.black10" />
      </Div>
    </StyledWorkTypesLink>
  </Link>
);
