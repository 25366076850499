import { array, string, object, InferType, mixed, number } from 'yup';

export const PROJECT_LS = 'projectReg';
export const PROJECT_LS_VERSION = 7;
export const PROJECT_ADDITIONAL_LS = 'projectAdditional';
export const DESCRIPTION_MAX_LENGTH = 500;

export type ApplicationRequired = 'yes' | 'no' | 'unsure';

// housing-type
export enum HouseType {
  APARTMENT = '1',
  SINGLE_HOME = '2',
  TOWNHOUSES = '3',
  TWO_MAN_APARTMENT = '4',
  LEISURE_RESIDENCE = '5',
  OTHER = '6',
  HOUSING_ASSOCIATION = '7',
}

export const getHouseTypeKey = (type: string): string => {
  const matchingHouseType = Object.entries(HouseType).find(([key, value]) => value === type);
  return matchingHouseType[0];
};

// project-flags
export enum Flags {
  NONE = '0',
  PROJECT_IS_FROM_MA = '2',
  MA_IS_REFERRER = '3',
}

// estimated-total-cost
export enum Size {
  SMALL = '1',
  MEDIUM = '2',
  LARGE = '3',
}

// Not a generic, just used to control which date inputs to show
export enum StartDateType {
  WHENEVER = 'whenever',
  NOW = 'now',
  ON_DATE = 'on_date',
  BEFORE_DATE = 'before_date',
  AFTER_DATE = 'after_date',
  START_NOT_SET = 'start_not_set',
}

export interface ProjectAdditionalLS {
  id: string;
  step: number;
  applicationRequired?: ApplicationRequired;
  size?: Size;
  requestStart?: {
    type: StartDateType;
    startDate?: Date;
    endDate?: Date;
  };
}

export const projectValidationSchema = object().shape({
  workTypes: array().of(string()).min(1).required('Påkrevd felt'),
  description: string().max(DESCRIPTION_MAX_LENGTH, `Beskrivelse kan ikke være over ${DESCRIPTION_MAX_LENGTH} tegn`),
  firstName: string().required('Påkrevd felt'),
  lastName: string().required('Påkrevd felt'),
  street: string().required('Påkrevd felt'),
  phone: string().required('Påkrevd felt'),
  email: string().email('Må være en gyldig epost').required('Påkrevd felt'),
  zip: string().required('Påkrevd felt'),
  houseType: mixed<HouseType>().oneOf(Object.values(HouseType)),

  // Values used in the background
  version: number().optional(),
});

export type ProjectSchema = InferType<typeof projectValidationSchema>;
export type PartialProjectSchema = Partial<ProjectSchema>;

export const queryIsHouseType = (query: string | string[]): query is HouseType => {
  const houseTypes = Object.values(HouseType) as string[];

  if (Array.isArray(query)) {
    return query.every((item) => houseTypes.includes(item));
  }

  return houseTypes.includes(query);
};
