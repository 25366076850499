import React from 'react';

import { Div } from '@schibsted-smb/fireball';
import useTranslation from 'next-translate/useTranslation';

import { Paragraph } from '~components/base/Paragraph';

import { Item } from './style';

interface Props {
  title: string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  displayBullets?: boolean;
  fontWeight?: number;
}

export const ListItem = ({ title, icon: Icon, displayBullets, fontWeight }: Props) => {
  const { t } = useTranslation();

  return (
    <Item>
      {displayBullets && (
        <Div display="flex" alignItems="center" alignContent="center">
          {Icon ? (
            <Icon width="16px" height="16px" fill="black.black0" />
          ) : (
            <Div width="16px" height="16px" borderRadius="50%" backgroundColor="#38999F" />
          )}
        </Div>
      )}
      <Paragraph fontWeight={fontWeight || 400} mb={0} ml={displayBullets ? '16px' : '0px'}>
        {t(title)}
      </Paragraph>
    </Item>
  );
};
