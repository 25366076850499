import React from 'react';

import { Div } from '@schibsted-smb/fireball';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

interface YoutubeEmbedProps {
  embedId: string;
  title: string;
  width?: string | string[];
  height?: string | string[];
}

export const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({ embedId, title, width, height }) => (
  <Div
    width={width || '100%'}
    height={height || ['160px', '165px', '186px', '239px', '318px', '480px', '603px', '663px', '762px', '843px']}
    borderRadius="3px"
    overflow="hidden"
  >
    <LiteYouTubeEmbed id={embedId} params="?cc_load_policy=1" poster="maxresdefault" webp title={title} />
  </Div>
);
