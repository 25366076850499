import React from 'react';

import { SectionWrapper } from '~components/elements/SectionWapper';

interface HeroContentProps {
  pt?: string[] | string;
}

export const HeroContent: React.FC<React.PropsWithChildren<HeroContentProps>> = ({ children, pt }) => (
  <SectionWrapper
    hasContainer
    divPaddingTop={pt || ['72px', null, null, null, '144px', '264px', '48px']}
    containerPaddingX="10px"
  >
    {children}
  </SectionWrapper>
);
