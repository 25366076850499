/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import { Div } from '@schibsted-smb/fireball';
import { NextPage } from 'next';

import { Footer } from '~components/layouts/Footer';
import { Header } from '~components/layouts/Header';
import { useXlFeatureToggle } from '~context/XlFeaturesContext';
import { useWindowSize } from '~hooks/useWindowSize';

interface PageLayoutProps {
  hideSignup?: boolean;
  hideFooter?: boolean;
}

export const PageLayout: React.FC<React.PropsWithChildren<PageLayoutProps>> = ({
  children,
  hideSignup,
  hideFooter,
}) => {
  const isSmall = useWindowSize().width < 541;
  const { isXlEnabled } = useXlFeatureToggle();
  return (
    <>
      {!isXlEnabled && <Header hideMenu={isXlEnabled && isSmall} />}
      <Div position="relative">
        {children}
        {!hideFooter && <Footer hideSignup={hideSignup} />}
      </Div>
    </>
  );
};

export const getLayout = (page: NextPage) => (
  <PageLayout hideSignup>
    <>{page}</>
  </PageLayout>
);
